const Modal = ({id, title = '', body, ok, cancel}) => {
    return (
        <div id={id}
            className="modal" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>{body}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={cancel.function}>{cancel.text}</button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={ok.function}>{ok.text}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal