// Production host
//export const apiHost = 'https://api.mskpedidos.com/api'
export const apiHost = 'https://www.api.mskmovil.com/api'

// Dev host
//export const apiHost = 'http://190.105.214.230:5001/api'

// Local host
//export const apiHost = 'https://localhost:44329/api'

// Local david
//export const apiHost = 'http://25.69.181.218:5001/api'
