// Core
import {Fragment, useState, useEffect, createContext} from 'react'
import {CSSTransition} from 'react-transition-group'
import { Switch, Route } from 'react-router-dom'

import myCookie from '../services/cookiesService'

import Sidebar from "./Sidebar"
import Module from "./BasicModule"
import Header from "./Header"

// Modules
import Clientes from "./modules/Clientes"
import Productos from "./modules/Productos"
import Usuarios from "./modules/Usuarios"
import OrderList from './modules/Order/OrderList'
import OrderSubmit from './modules/Order/OrderSubmit'
import Companies from './modules/Companies'
import Users from './modules/Users'

export const HomeContext = createContext();

const routes = [
    {
        id: 0,
        route: 'Pedidos',
        component: <OrderSubmit />,
    },
    {
        id: 1,
        route: 'Pedido_Pendiente',
        component: <OrderList />,
    },
    {
        id: 2,
        route: 'Productos',
        component: <Productos />,
    },
    {
        id: 3,
        route: 'Clientes',
        component: <Clientes />,
    },
    {
        id: 4,
        route: 'Usuarios',
        component: <Usuarios />,
    },
    {
        id: 5,
        route: 'Alta_De_Empresas',
        component: <Companies />
    }
]

// Posible optimizacion:
// TODO: Agregar contexto que contenga el modulo cargado y el usuario
const Home = ({handleSignOut}) => {
    const [module, setModule] = useState({id: -1, component: null});
    const [inProp, setInProp] = useState(false);

    const voidFunc = () => {
        console.error('Restart not implemented')
    }

    // Stores into the state an anonymous function
    const [restartFunction, setRestartFunction] = useState(() => voidFunc);

    const user = myCookie.user.get();

    if (user === undefined) {
        handleSignOut();
    }

    const handleSelectSidebarModule = (newModule, moduleid) => {
        setInProp(false)
        setModule({id: moduleid, component: newModule});
    }

    useEffect( () => {
        setInProp(true)
    },[module.id])

    return (
        <Fragment>
            <HomeContext.Provider
                value={{
                    module,
                    restartFunction,
                    setRestartFunction
                }}>

                <Header name={user.name}/>

                <div className="container-fluid" >
                    <div className="row">

                        <Sidebar 
                            handleClick={handleSelectSidebarModule}
                            handleSignOut={handleSignOut}
                        />

                        <CSSTransition in={inProp} timeout={500} classNames="home" exit={false}>        
                            <Switch>

                                {/* <Route
                                    path="/Pedidos"
                                    children={() => <Module module={module.component}/>}
                                /> */}

                                {routes.map( r => (
                                    <Route
                                        path={`/${r.route}`}
                                        children={<Module module={r.component}/>}
                                    />
                                ))}

                            </Switch>
                        </CSSTransition>

                    </div>
                </div>
            </HomeContext.Provider>

        </Fragment>
    );
}

export default Home